<template>
  <div class="my-notice">
    <div class="notice-box">
      <div class="botice-content" @click="goNotice">{{ notice }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notice: '关于马栏山渲染平台和存储市场化运营的告知函',
    };
  },
  methods: {
    goNotice() {
      this.$router.push('/biddingDetail');
    },
  },
};
</script>
<style lang="scss" scoped>
.my-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  padding: 0 160px;
  background: #F8F8F8;

  .notice-box {
    width: 420px;
    overflow: hidden; /* 隐藏溢出的内容 */
    white-space: nowrap; /* 防止文本换行 */

    .botice-content {
      display: inline-block;
      padding-left: 100%; /* 初始位置在容器外部 */
      color: #262626;
      font-size: 14px;
      cursor: pointer;
      animation: scrollText 10s linear infinite; /* 滚动动画 */

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @keyframes scrollText {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%); /* 移动到容器外 */
    }
  }
}
</style>
